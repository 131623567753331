import React from 'react';
import styles from './ThirdScreen.module.scss';
import BackgroundImage from 'gatsby-background-image';

export default ({ backgroundImage }) => (
  <section className={styles.screenBox}>
    <BackgroundImage
      Tag="div"
      className={styles.pictureProducts}
      fluid={backgroundImage}
      fadeIn={false}
      loading="eager"
    ></BackgroundImage>
  </section>
);
