import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import VisibilitySensor from 'react-visibility-sensor';

import styles from './FourthScreen.module.scss';
import DispensariesMap from '../shared/dispensaries-map/DispensariesMap';
import recycleChillLogo from '../../images/recycle-chill-logo.svg';

class FourthScreen extends React.Component<any> {
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      animationTrigerred: false,
    };
  }

  render() {
    const { productImg } = this.props;

    return (
      <section className={styles.container}>
        <div className={styles.backgroundTriangleTop} />

        <div className={styles.backgroundTriangleBottom} />

        <div className={styles.cardHeader}>
          <VisibilitySensor
            active={!this.state.animationTrigerred}
            scrollCheck
            scrollThrottle={10}
            onChange={(isVisible) => {
              if (isVisible && !this.state.animationTrigerred) {
                this.setState({ animationTrigerred: true });
              }
            }}
          >
            {({ isVisible }) => (
              <div className={styles.description}>
                <img src={recycleChillLogo} className={styles.recyclingLogo} alt="LOGO" />
                <h2 className={isVisible ? styles.showH2 : 'remove-transform'}>
                  We’re Green inside <br />
                  and out!
                </h2>
                <h5>
                  Just like you, we love the planet. Which is why we are proud to partner with
                  GAIACA to provide recycling for used CLICK devices. Look for our sign wherever you
                  buy CLICK and recycle your device when finished. Thanks!
                </h5>
              </div>
            )}
          </VisibilitySensor>
          <BackgroundImage Tag="div" className={styles.cardImg} fluid={productImg} fadeIn={false} />
        </div>

        <div className={styles.coloredLineTop} />

        <VisibilitySensor
          partialVisibility
          scrollCheck
          scrollThrottle={10}
          onChange={this.props.setDispensariesIsVisible}
        >
          <DispensariesMap setRef={this.props.dispensariesBlockRef} />
        </VisibilitySensor>
      </section>
    );
  }
}

export default FourthScreen;
