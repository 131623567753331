import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import styles from './SecondScreen.module.scss';

import evolvedIcon from '../../images/evolved_blue.svg';
import ergonomicIcon from '../../images/ergonomic_blue.svg';
import tactileIcon from '../../images/tactile_blue.svg';

export default class SecondScreen extends React.Component<any> {
  state: any;

  constructor(props) {
    super(props);

    this.state = {
      slideIndex: 1,
      animationTrigerred: false,
    };

    this.changeSlide = this.changeSlide.bind(this);
  }

  changeSlide(id) {
    this.setState({ slideIndex: id });
  }

  render() {
    return (
      <section className={styles.screenBox}>
        <div className={styles.blueTriangle}></div>
        <VisibilitySensor
          active={!this.state.animationTrigerred}
          scrollCheck
          scrollThrottle={10}
          onChange={(isVisible) => {
            if (isVisible && !this.state.animationTrigerred) {
              this.setState({ animationTrigerred: true });
            }
          }}
        >
          {({ isVisible }) => (
            <div className={styles.topText}>
              <h1 className={isVisible ? styles.showFromLeft : 'remove-transform'}>
                Fast-acting, discreet, and great-tasting
              </h1>
              <p className={isVisible ? styles.showFromRight : 'remove-transform'}>
                CLICK uses innovative nano-technology to create a fine, light mist and perfectly
                balanced formula. As fast acting as a vape, as portable as an edible, this advanced
                cannabis spray is the best of both worlds. CLICK everywhere. CLICK anywhere. CLICK
                is ready for anything.
                <br />
                <br />
                CLICK spray is the winner of High Times Cannabis Cup, Emerald Cup, WeedCon Cup, and
                Clio Cannabis Awards.
              </p>
            </div>
          )}
        </VisibilitySensor>
        <div className={styles.slider}>
          <Slide
            activeSlideIndex={this.state.slideIndex}
            img={this.props.productImageBack}
            index={0}
            changeSlide={(id) => this.changeSlide(id)}
          />
          <Slide
            activeSlideIndex={this.state.slideIndex}
            img={this.props.productImageFront}
            index={1}
            changeSlide={(id) => this.changeSlide(id)}
          />
          <Slide
            activeSlideIndex={this.state.slideIndex}
            img={this.props.productImageBack}
            index={2}
            changeSlide={(id) => this.changeSlide(id)}
          />
        </div>
        <div className={styles.bottomBox}>
          <div className={styles.circleControlsWrapper}>
            <CircleControl
              changeSlide={(id) => this.changeSlide(id)}
              index={0}
              activeSlideIndex={this.state.slideIndex}
              img={evolvedIcon}
            />
            <CircleControl
              changeSlide={(id) => this.changeSlide(id)}
              index={1}
              activeSlideIndex={this.state.slideIndex}
              img={ergonomicIcon}
            />
            <CircleControl
              changeSlide={(id) => this.changeSlide(id)}
              index={2}
              activeSlideIndex={this.state.slideIndex}
              img={tactileIcon}
            />
          </div>
          {this.state.slideIndex === 0 ? (
            <div className={styles.textBox}>
              <div>
                <h6>evolved</h6>
              </div>
              <div className={styles.paragraphsBox}>
                <p>
                  CLICK is based in nanotechnology. Which means that we made big things small. Which
                  in turn makes small things BIG! We have been able to combine ingredients together
                  in the most efficient way so that you always get the exact same dose every time
                  you CLICK. We just took the guessing out of cannabis!
                </p>
              </div>
            </div>
          ) : null}
          {this.state.slideIndex === 1 ? (
            <div className={styles.textBox}>
              <div>
                <h6>ERGONOMIC</h6>
              </div>
              <div className={styles.paragraphsBox}>
                <p>
                  CLICK’s patented design was built with you in mind. It’s triangle shape prohibits
                  it from rolling off a desk while the smooth-to-the-touch material helps it stay
                  secure in your hand.
                </p>
              </div>
            </div>
          ) : null}
          {this.state.slideIndex === 2 ? (
            <div className={styles.textBox}>
              <div>
                <h6>TACTILE</h6>
              </div>
              <div className={styles.paragraphsBox}>
                <p>
                  CLICK features a built in key chain clip. Click it on your keyring, handbag, gym
                  bag or anything else with a clip. So you can CLICK and go!
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

class Slide extends Component<any> {
  render() {
    const { index, activeSlideIndex, img } = this.props;
    return (
      <div
        className={styles.slide + ' ' + (index === activeSlideIndex ? styles.active : '')}
        onClick={() => {
          this.props.changeSlide(index);
        }}
      >
        <img src={img.src} alt="product feature"></img>
      </div>
    );
  }
}

class CircleControl extends Component<any> {
  render() {
    const { index, activeSlideIndex, img } = this.props;
    return (
      <div
        className={
          (activeSlideIndex === index ? styles.activeControl : null) + ' ' + styles.circleControl
        }
        onClick={() => {
          this.props.changeSlide(index);
        }}
      >
        <img src={img} alt="icon"></img>
      </div>
    );
  }
}
