
import React from 'react';
import classNames from 'classnames';
import { Link, useStaticQuery, graphql } from 'gatsby';

import styles from './ButtonsBar.module.scss';
import whiteTriangle from '../../../images/white-triangle.svg';
import BackgroundImage from 'gatsby-background-image';

const products = ['go', 'restore', 'chill', 'dream'];
interface Props {
  showText: boolean;
  className?: string;
}

const ButtonsBar: React.FC<Props> = ({ showText, className }) => {
  const images = useStaticQuery(
    graphql`
      query {
        restore: file(relativePath: { eq: "restore.png" }) {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        go: file(relativePath: { eq: "go.png" }) {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        chill: file(relativePath: { eq: "chill.png" }) {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dream: file(relativePath: { eq: "dream.png" }) {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  return (
    <nav className={classNames(styles.buttonsBar, className)}>
      {products.map((product) => (
        <Link
          key={product}
          to={`https://clickspray.com/${product}`}
          className={styles.buttonWrapper}
          activeClassName={styles.active}
        >
          {showText && (
            <div className={styles.title}>
              <div className={styles.name}>{product.toUpperCase()}</div>
            </div>
          )}

          <div className={classNames(styles.button, styles[`${product}Button`])}>
            <img src={whiteTriangle} alt="triangle"></img>
          </div>

          {!showText && (
            <div className={styles.card}>
              <BackgroundImage
                Tag="div"
                fadeIn={false}
                className={styles.cardImg}
                fluid={images[product].childImageSharp.fluid}
              >
                <div className={styles.name}>{product.toUpperCase()}</div>
              </BackgroundImage>
            </div>
          )}
        </Link>
      ))}
    </nav>
  );
};

export default ButtonsBar;