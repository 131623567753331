import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import FirstScreen from 'components/about/FirstScreen';
import FourthScreen from 'components/about/FourthScreen';
import SecondScreen from 'components/about/SecondScreen';
import ThirdScreen from 'components/about/ThirdScreen';
import Layout from 'components/layouts/main/Layout';

interface Props {
  data: any;
  path: string;
}

export default class AboutPage extends React.Component<Props> {
  dispensariesBlockRef: React.RefObject<unknown>;

  constructor(props: Props) {
    super(props);

    this.dispensariesBlockRef = React.createRef();

    this.state = {
      dispensariesIsVisible: false,
    };

    this.setDispensariesIsVisible = this.setDispensariesIsVisible.bind(this);
  }

  setDispensariesIsVisible(dispensariesIsVisible: boolean) {
    this.setState({ dispensariesIsVisible });
  }

  render(): React.ReactNode {
    const { data } = this.props;

    return (
      <Layout
        location={this.props.path}
        setNavAbsolute={this.state?.dispensariesIsVisible}
        dispensariesBlockRef={this.dispensariesBlockRef}
      >
        <Helmet>
          <title>About</title>
        </Helmet>

        <FirstScreen backgroundImage={data.bgImgFirstScreen.childImageSharp.fluid} />

        <SecondScreen
          productImageFront={data.productImageFront.childImageSharp.fluid}
          productImageBack={data.productImageBack.childImageSharp.fluid}
        />

        <ThirdScreen backgroundImage={data.bgImgThirdScreen.childImageSharp.fluid} />

        <FourthScreen
          productImg={data.productImg.childImageSharp.fluid}
          setDispensariesIsVisible={this.setDispensariesIsVisible}
          dispensariesBlockRef={this.dispensariesBlockRef}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    bgImgFirstScreen: file(relativePath: { eq: "Sunglasses.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImgThirdScreen: file(relativePath: { eq: "about-third-screen-bg.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productImg: file(relativePath: { eq: "CLICK_GO_61020-01.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productImageFront: file(relativePath: { eq: "chill-product-image-front.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productImageBack: file(relativePath: { eq: "chill-product-image-back.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
